import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors, md } from '../common'
import Q from '../images/q.png'

export default () => (
  <Layout>
    <SEO title="FAQs" />

    <h1 css={`text-align:center;color:${colors.green};margin-top:4rem;`}>FREQUENTLY ASKED QUESTIONS</h1>
    <StaticQuery
      query={graphql`
        query FAQ_Query {
          allFaqJson {
            edges {
              node {
                question
                answer
              }
            }
          }
        }
        `}
        render={ data => (
          <Wrap>
            {data.allFaqJson.edges.map((qa, index) => {
              return  <Card key={index}>
                        <h2>{qa.node.question}</h2>
                        <p>{qa.node.answer}</p>
                      </Card>
            })}
          </Wrap>
        )}
    />
  </Layout>
)

const Wrap = styled.div`
  display:flex;
  flex-wrap:wrap;
  padding: 2rem 8rem;

  ${md} {
    padding: 1rem;
  }
`

const Card = styled.div`
  background-image: url(${Q});
  background-size:contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  min-height: 300px;
  margin: 1rem 8rem;
  width:100%;
  padding-left: 6rem;
  display:flex;
  flex-direction:column;
  justify-content: flex-end;

  p {
    margin-bottom:1.75rem;
    margin-left:1rem;
  }

  ${md} {
    padding:0;
    margin:0;
    justify-content: center;

    p {
      margin:0;
    }
  }
`
